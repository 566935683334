import React, { Fragment } from 'react'
import { Navigate, Route } from 'react-router-dom'

import ROUTES from 'constants/routes'

import { AuthLayout, FullScreenLayout } from 'components/layouts'
import { SingleProject, NotFoundPage } from 'screens/common'
import ProjectDiveRecords from '../../screens/common/projectDiveRecords/ProjectDiveRecords'
import { ProfileScreen as DiverProfile } from 'screens/diver/profile'
import { ProfileScreen as ContractorProfile } from 'screens/divingContractor'
import DiveRecordSummary from 'screens/diver/DiveRecordSummary'
import { HelpAndInfo } from 'screens/common/helpAndInfo'
import DiveRecordVerificationLevels from 'screens/common/helpAndInfo/DiveRecordVerificationLevels'
import Release090 from '../../screens/common/helpAndInfo/release/Release090'
import Release091 from '../../screens/common/helpAndInfo/release/Release091'
import Release100 from 'screens/common/helpAndInfo/release/Release100'
import CreateAProject from '../../screens/common/helpAndInfo/guids/CreateAProject'
import CreateHistoricalDiveRecord from '../../screens/common/helpAndInfo/guids/CreateHistoricalDiveRecord'
import CreatePersonalAccount from '../../screens/common/helpAndInfo/guids/CreatePersonalAccount'
import SendDiveRecordForVerificationLevelOne from '../../screens/common/helpAndInfo/guids/SendDiveRecordForVerificationLevelOne'
import SignUpAsOrganization from '../../screens/common/helpAndInfo/guids/SignUpAsOrganization'
import UpdateOrganizationProfile from '../../screens/common/helpAndInfo/guids/UpdateOrganizationProfile'
import UpdatePersonalInformation from '../../screens/common/helpAndInfo/guids/UpdatePersonalInformation'
import VerifyDiveRecordAsASupervisorLevelOne from '../../screens/common/helpAndInfo/guids/VerifyDiveRecordAsASupervisorLevelOne'
import VerifyIdentityUsingPersona from '../../screens/common/helpAndInfo/guids/VerifyIdentityUsingPersona'

const CommonUserRoutes = () => {
    return (
        <Fragment>
            <Route element={<AuthLayout />}>
                <Route
                    path={`${ROUTES.PROJECT}/:id`}
                    element={<SingleProject />}
                />
                <Route
                    path={`${ROUTES.PROJECT}/:id/${ROUTES.DIVE_RECORDS}`}
                    element={<ProjectDiveRecords />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:id`}
                    element={<SingleProject />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:id/${ROUTES.MY_PROJECT_RECORDS}`}
                    element={<ProjectDiveRecords />}
                />
                <Route
                    path={`${ROUTES.DIVER_PROFILE}/:profileHash`}
                    element={<DiverProfile />}
                />
                <Route
                    path={`${ROUTES.DIVING_CONTRACTOR_PROFILE}/:profileHash`}
                    element={<ContractorProfile />}
                />
                <Route
                    path={`${ROUTES.MY_DIVE_PROJECTS}/:projectId/${ROUTES.MY_PROJECT_RECORDS}/:diveRecordId`}
                    element={<DiveRecordSummary />}
                />
                <Route path={ROUTES.HELP_AND_INFO} element={<HelpAndInfo />}>
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.CREATE_A_PROJECT}`}
                        element={<CreateAProject />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.CREATE_HISTORICAL_DIVE_RECORD}`}
                        element={<CreateHistoricalDiveRecord />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.CREATE_PERSONAL_ACCOUNT}`}
                        element={<CreatePersonalAccount />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE}`}
                        element={<SendDiveRecordForVerificationLevelOne />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.SIGN_UP_AS_ORGANIZATION}`}
                        element={<SignUpAsOrganization />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.UPDATE_ORGANIZATION_PROFILE}`}
                        element={<UpdateOrganizationProfile />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.UPDATE_PERSONAL_INFORMATION}`}
                        element={<UpdatePersonalInformation />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE}`}
                        element={<VerifyDiveRecordAsASupervisorLevelOne />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFY_IDENTITY_USING_PERSONA}`}
                        element={<VerifyIdentityUsingPersona />}
                    />
                    <Route
                        path={`${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`}
                        element={<DiveRecordVerificationLevels />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`}
                        element={<Release090 />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`}
                        element={<Release091 />}
                    />
                    <Route
                        path={`${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`}
                        element={<Release100 />}
                    />
                </Route>
            </Route>
            <Route element={<FullScreenLayout />}>
                <Route
                    path={ROUTES.NOT_FOUND_PAGE}
                    element={<NotFoundPage />}
                />
            </Route>
            <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Fragment>
    )
}

export default CommonUserRoutes
