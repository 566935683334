import React from 'react'

const UpdatePersonalInformation = () => {

    return (
        <div className={'guidde'}>

            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to update personal information on Skill N Depth</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/kFKAw87mQuNMQ5XPXKn4uy"
                        title="How to update personal information on Skill N Depth"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen="true"
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Keeping your profile up-to-date on Skill N Depth ensures that you present
                        your best self to potential employers and colleagues.
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    Go to{" "}
                    <a href="https://app.skillndepth.com" target="_blank">
                        app.skillndepth.com
                    </a>
                </h3>{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Click on your name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        {" "}
                        Let’s walk through the process together—it’s simple and will only take a
                        few minutes! While using Skill N Depth app you will have your profile page
                        simply accessible. Start by clicking on your name in the top right corner
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fnw5tmzULt8YgdAxACMiCXw_doc.png?alt=media&token=62f7fe14-aa17-46f1-911e-b61343bbb49f"
                    alt="Click on your name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click "Profile"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        In the dropdown menu, click on “Profile.” This is where you can view and
                        edit your information.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FesiBsYNUzhuUT8orwHPYVJ_doc.png?alt=media&token=f5898688-34dd-4394-9a3c-c3281d3b7bbe"
                    alt="Click 'Profile'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Click edit button
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Look for the little edit icon. Click it, and voila! You’re ready to start
                        making changes.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F4RQu7PZ5TiPT7fePgdbZGe_doc.png?alt=media&token=99916a2d-440a-4485-a14e-aa0c35391512"
                    alt="Click edit button"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Click "Available for hire"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Start by selecting your availability
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fww5uSN2Rj4wxqsmiQpYw5s_doc.png?alt=media&token=1ac433c3-06f6-4e67-8551-6252241c9dfd"
                    alt="Click 'Available for hire'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Select your availability
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Are you open to new opportunities, or maybe taking a break? Click on this
                        option to select your current status, like “Currently unavailable” if
                        you’re taking some time off.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FbQeUSjLWVoyZ1LYswXUQUW_doc.png?alt=media&token=646233de-02a8-4362-903b-8a1fd7ec8b89"
                    alt="Select your availability"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Select profile visibility
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, choose if your profile is hidden or not. If you prefer to keep a low
                        profile and don’t want to appear in searches, simply check the Hidden box.
                        It’s like going incognito but for your profile! Click on the info icon to
                        receive more detail about what will change if you choose to hide your
                        profile
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FwGb4qeFZUihCPwWxfhEwe8_doc.png?alt=media&token=ba320fc7-d825-4ba4-b582-8abe2ad75d54"
                    alt="Select profile visibility"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Adjust First name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click on the First name field and adjust in your first name if needed
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FcaJsAQG6tcUBy3rxRC3rsb_doc.png?alt=media&token=36783996-0070-443c-99db-d625adacb220"
                    alt="Adjust First name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Enter your Middle name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If you have a middle name or initial, you can update it here
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fmpddmz2tngxM16g3a1gbZY_doc.png?alt=media&token=75f3dbb7-26d6-491f-9fec-2417cfb8421b"
                    alt="Enter your Middle name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Enter Last name
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Make sure your last name is correct, and update it if needed
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FgWG4Fbhz1FkvB3e1cveQ2k_doc.png?alt=media&token=ba73baaa-1fd2-43f1-84b7-23f5de733fe2"
                    alt="Enter Last name"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Enter Current title
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Are you sporting a new title? Update it in the Current title field so
                        everyone knows what you’re up to.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F5FM7fTLbFW3TUtUcTXSZWQ_doc.png?alt=media&token=e0af7517-a3e6-4242-bcb9-128a0e89940d"
                    alt="Enter Current title"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Enter Date of birth
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click on the field and select your birth month and day. It’s a small
                        detail, but it helps complete your profile.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FqqeNvEczbv6Fo37YxmuTHF_doc.png?alt=media&token=9a070d7c-e3a3-4dcc-90b6-4969f166d998"
                    alt="Enter Date of birth"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Select your roles
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, select all roles that showcase your expertise.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F2KaM24u5s75Lb4kSyMugsL_doc.png?alt=media&token=da4384d8-87dd-4c5e-b4cc-a197b0d8c724"
                    alt="Select your roles"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Choose your country of residence
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Let everyone know where you’re based by selecting your country from the
                        dropdown menu.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FefN1AaZM8E5rK54xZHWg13_doc.png?alt=media&token=9156354a-42a1-4282-b2ff-00c491a9e8d5"
                    alt="Choose your country of residence"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Click on the nationality field
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Update your nationality by clicking on the “Nationality” field and typing
                        it in
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fv4bx1MSgubNiC31Dbtzkbs_doc.png?alt=media&token=d910e6be-9628-48da-93f0-d0db89d6b7c9"
                    alt="Click on the nationality field"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Add languages
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Do you speak more than one language? Add them here by choosing from the
                        list—being multilingual is a great asset!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FrVVqfu3RphwzEL9SWBanrf_doc.png?alt=media&token=4fefc4ef-2c05-4d75-816f-3f3505e61b5d"
                    alt="Add languages"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Enter your email address
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Make sure your email address is current so we can reach you
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fc9hDJonz25WTXGFRm48nqM_doc.png?alt=media&token=1ecff832-ed32-4727-a4c9-d10ebd406a82"
                    alt="Enter your email address"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    17. Adjust your phone number
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, update your phone number
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FnuaMM1Xn8igZyj6k4MpU3N_doc.png?alt=media&token=aecb086e-04e4-43e5-ac4e-0f31b5cdc7bd"
                    alt="Adjust your phone number"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    18. Adjust About me section
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        This is your chance to share a little about yourself. Click on this
                        section and type in a brief introduction or summary that reflects who you
                        are.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2F2evQEACeh46UBxERQpzuMa_doc.png?alt=media&token=c3101086-ec77-4e47-bb4e-d0caf3170bb2"
                    alt="Adjust About me section"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    19. Adjust your professional summary section
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Review and polish your professional summary to make sure it accurately
                        reflects your experience and skills.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fj6FvwSW94dfJyQvNvJVWSg_doc.png?alt=media&token=76ed520a-5964-4fdb-b542-0caaba0fe25e"
                    alt="Adjust your professional summary section"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    20. Preferred regions of work
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Where would you love to work? Click on this field and pick the regions
                        that appeal to you most from the list.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2Fu5ZJBSiQsttEtpZArcJ78i_doc.png?alt=media&token=ed414514-3d9a-4ec1-b668-32f2344ba5d2"
                    alt="Preferred regions of work"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    21. Click "Save personal information"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’ve made all your changes, don’t forget to click the “Save
                        personal information” button. This will lock in all your updates and
                        ensure your profile is ready to shine!
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2FkFKAw87mQuNMQ5XPXKn4uy%2FkAw3uS22ibNgFkujiriAdH_doc.png?alt=media&token=51f633e7-151e-4b84-b7b1-e9ed09717202"
                    alt="Click 'Save personal information'"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        And that’s it! You’re all set with an updated profile on Skill N Depth.
                        Whether you’re actively looking for new opportunities or just keeping your
                        profile current, these small steps can make a big difference in how you’re
                        perceived by others in the industry. Happy updating!
                    </p>
                </div>
            </div>

        </div>
    )
}

export default UpdatePersonalInformation
