import React from 'react'

const CreateHistoricalDiveRecord = () => {

    return (
        <div className={'guidde'}>
            <div>
                <div style={{ textAlign: "center" }}>
                    <div>
                        <h2>How to create a historical dive record</h2>
                    </div>
                </div>
                <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            left: 0,
                            top: 0
                        }}
                        src="https://embed.app.guidde.com/playbooks/v2GGphqvEwvd5qb7LfJBdg"
                        title="How to create a historical dive record"
                        frameBorder={0}
                        referrerPolicy="unsafe-url"
                        allowFullScreen="true"
                        allow="clipboard-write"
                        sandbox="allow-popups allow-popups-to-escape-sandbox allow-scripts allow-forms allow-same-origin allow-presentation"
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Hey there! Ready to log a dive from your past and make it part of your
                        official dive records? No worries—we’ve got you covered! This step-by-step
                        guide will walk you through the entire process of creating a historical
                        dive record on SkillNDepth. Let’s dive right in and make sure everything
                        is accurately captured.
                    </p>
                </div>
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    1. Introduction
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        First, head over to the SkillNDepth platform.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F2V6vMVtypewmFy89zZjG17_doc.png?alt=media&token=0e2f701f-03b7-47cf-815f-42094077b8d6"
                    alt="Introduction"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    2. Click "Create a dive record"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, click on “Create a Dive Record.” This will open up a new form where
                        you can start entering the details of your dive.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fsg88ejUMbW8Fb9sfpY4oD9_doc.png?alt=media&token=be0d5eba-fa68-402c-be48-bccf5f5fa14d"
                    alt="Click 'Create a dive record'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    3. Select role that you had
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, pick the role you had during the dive—whether you were a Diver or a
                        Dive Supervisor. This choice helps categorize your record correctly.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fdj2ppPm7YWHkKytao52QER_doc.png?alt=media&token=a6117856-4e8e-4cc3-997b-858d42c310b1"
                    alt="Select role that you had"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    4. Select diving mode
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next up, choose the diving mode that was used for this particular dive.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F777xKz1EwyjG3QJJrPhM6G_doc.png?alt=media&token=18499ed7-6558-45f6-a007-2a399dd288e3"
                    alt="Select diving mode"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    5. Click "Continue"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Click Continue to proceed to the next step.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F58MutN1yCnSgUGWVezC28j_doc.png?alt=media&token=8adbcbbd-1f7d-49cb-b490-cbcd66749ae8"
                    alt="Click 'Continue'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    6. Fill in location details
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        It’s time to set the scene! Start by providing the location details
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FebSA6KjaVLMAojb4TSA3Ns_doc.png?alt=media&token=b29e8703-217a-4148-8a25-7ba6668b7471"
                    alt="Fill in location details"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    7. Input geographical data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, provide geographical data
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FoBnYtr2Tkrc8JVoRxN4R2F_doc.png?alt=media&token=3ca8f109-7c3f-4e13-a359-0011a10494fb"
                    alt="Input geographical data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    8. Enter dive details
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Next, enter details about the dive itself. Details include Diving mode and
                        diving platform as mandatory fields. Dive physical characteristic can be
                        left empty
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FrZa1iz6srAwu5RpyX9sZCv_doc.png?alt=media&token=0709f01f-3882-4cc9-b609-f1fa542957a7"
                    alt="Enter dive details"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    9. Enter environmental conditions
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Though optional, this section is super helpful. Provide information about
                        the environmental conditions during the dive. It’s a great way to paint a
                        full picture of your experience.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fdn1DnhU9A2ASxzev8G6k43_doc.png?alt=media&token=57441044-5be3-447a-a266-3184d715cc11"
                    alt="Enter environmental conditions"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    10. Click next
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you are satisfied with data entered click on next to go to he next
                        step
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fs1FUzErpi6jUbZ1cJEd27p_doc.png?alt=media&token=fafdfe5b-2959-4fb0-94ed-e18e59b69faa"
                    alt="Click next"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    11. Choose the breathing apparatus type.
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Select the type of breathing apparatus you used
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Ff35xAmVyq3NECRbavWihoo_doc.png?alt=media&token=9bc9101b-a184-4362-b9c3-215d05a49ce0"
                    alt="Choose the breathing apparatus type."
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    12. Enter data about the equipment
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        While these fields are optional, it’s always nice to give a complete
                        picture of the dive by listing the equipment you used. This includes
                        everything from your suit to any specialized equipment.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FachDC1jmZy3UNTWRAbiyjJ_doc.png?alt=media&token=9808500c-f92e-49eb-9411-c017a9e7664a"
                    alt="Enter data about the equipment"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    13. Add gas cylinder used
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Time to log your gas cylinder details.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FspqCVa1BfsVr8te7eUB6v9_doc.png?alt=media&token=46d0d1b9-874b-4996-b8c6-f1c146c3299a"
                    alt="Add gas cylinder used"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    14. Input data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        {" "}
                        Start by inputting the data
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F2ogxqJjxY1L9ubRHmpzey8_doc.png?alt=media&token=08502cec-cd75-4299-abc9-fd5291dff615"
                    alt="Input data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    15. Click on Add gas cylinder
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Then click on “Add Gas Cylinder” to save it.{" "}
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fjs14WWwzXruPGRdKAeDM6D_doc.png?alt=media&token=2c956e93-713c-480b-aa85-c406a56fcb39"
                    alt="Click on Add gas cylinder"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    16. Observe added cylinder
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        You’ll see a list of all the gas cylinders you’ve added at the bottom.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F3pDTkyXdWhE6LX21rf13uo_doc.png?alt=media&token=eb564be7-2ad0-4d68-8303-3d8d0ac8cf70"
                    alt="Observe added cylinder"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    17. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proceed to the next section.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F9itzmAXC94sb7omwX7KLsb_doc.png?alt=media&token=228370db-d60f-450e-999c-d67483c0c3ef"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    18. Click "Add dive event"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Depending on the diving mode selected on the first step of creation of the
                        dive record you will get different options here. For example, for Scuba
                        diving we need to enter data about the dive event. No matter what section
                        shows the process is the same. Click on Add button, add data, and save it,
                        It is really easy. So lets click on add dive event button here
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2F4HgNNqLujG3ASpxAtQz5qQ_doc.png?alt=media&token=b3621950-dccd-4890-9465-3678734619d8"
                    alt="Click 'Add dive event'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    19. Fill in the form
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proved all data required by the form. Mandatory fields are marked with a
                        star next to the title of the field.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FxejEDzxoATiPrRxdFhvFsa_doc.png?alt=media&token=8b9e31a8-ae19-40b4-b60a-de22e7690837"
                    alt="Fill in the form"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    20. Click "Save"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Save the data.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FwM8gMkTthimuQDFUaqURxZ_doc.png?alt=media&token=5b6ee5d6-d24c-4992-824f-7881b07e9ef8"
                    alt="Click 'Save'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    21. Double-check the data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If everything went smoothly you should have a card with all data entered.
                        Double check it and if you are happy proceed. Keep in mind that some dive
                        modes have multiple cards that need to be entered.{" "}
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fm4KR9FdtCmRY4NNrHmnQNA_doc.png?alt=media&token=85717cff-641e-42e2-8b6e-65eebb710eb7"
                    alt="Double-check the data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    22. Chek option at the top
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Each page has a header with an indication of the current step and a list
                        of all steps required. You can click Go back to get to the Skill N Depth
                        app, or you can click on one of the previous steps in order to get back to
                        that step.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fxf2R9KriskGWUuS8W3CMQy_doc.png?alt=media&token=ea812fa0-b867-4af9-9ed6-169343bf4ddd"
                    alt="Chek option at the top"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    23. Keep in mind save And continue later option
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If you’re not done or need to take a break, no problem! Use the “Save and
                        Continue Later” option to save your progress. Only you will be able to see
                        this draft until you’re ready to finalize it.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FaHaCibLBHu5jN7k9ep2NhU_doc.png?alt=media&token=95c248bc-4580-4523-81a8-db24c1ccf2a8"
                    alt="Keep in mind save And continue later option"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    24. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proceed to the next step.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FbRa8qhHqHraEdkBphSFC5F_doc.png?alt=media&token=cfa715d8-3e39-4b2e-9398-4ef487122965"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    25. Enter tasks perfomed
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, you’ll need to list the tasks you performed during the dive. This
                        could include inspections, repairs, or any other activities.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FxhFJyutmdpTJT5HDBrxUck_doc.png?alt=media&token=ce32bbc5-8bbd-4fe4-9416-a962230a7352"
                    alt="Enter tasks perfomed"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    26. Add data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose the relevant structures, systems, devices, objects, and materials.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fixf9SgfLNyb3aaNqYqZyc2_doc.png?alt=media&token=3ae316c3-e309-4fb6-876c-29b8ee56e4fe"
                    alt="Add data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    27. Add activities
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now, select all the activities you performed during the dive
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FmVraPzHoR5sDAGBKQBdi8d_doc.png?alt=media&token=0b491f11-83d9-4c34-870a-ec7f368fb7bb"
                    alt="Add activities"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    28. Select all tools used
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Choose the tools that were used during the dive. This provides additional
                        context to the tasks performed.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FdbGZ2cjyZGGubqt72xkbnt_doc.png?alt=media&token=1af68b8e-b41e-4dcd-9924-340c4a6f7cb6"
                    alt="Select all tools used"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    29. Click "Next"
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Proceed to the next section.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FkeAXP4YXZwyxBJx26UatVL_doc.png?alt=media&token=6cf3e404-52e3-4607-b2f3-2cbc4900f565"
                    alt="Click 'Next'"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    30. Enter data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Input information about your rest, diet, hydration, medications,
                        supplements, and physical activity on the day of the dive. While these
                        fields are optional, they add valuable context to your record.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FkD6PaFPHBhAWb4UjU1fVz3_doc.png?alt=media&token=ec5346f2-123e-4c19-b91e-89a75b2de1d4"
                    alt="Enter data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    31. Enter data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Enter details regarding your mental wellness and hygiene. This step is
                        important for providing a holistic view of your dive day.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2Fd3Z28E7Lt31bR2guXKWLFN_doc.png?alt=media&token=b879a2c5-65dd-47bd-911f-720af6cd614e"
                    alt="Enter data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    32. Enter data
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        The next step is really important. It is about incidents. We take the
                        security and wellbeing of divers very seriously. If there have been any
                        incidents please describe them and give as much detail as possible, Only
                        by being transparent we can improve the standards in our industry.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FcitUHRtzUf3MyGzmKc7sSk_doc.png?alt=media&token=98e6da59-d715-43b7-b963-ee4c980a263a"
                    alt="Enter data"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    33. Upload any documents
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        If you have any relevant documents or pictures related to the dive, you
                        can upload them here
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FpPG8MPCwiTprAaj6kD4h4L_doc.png?alt=media&token=632334bd-e8bd-4cce-a801-012a2ca95e22"
                    alt="Upload any documents"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    34. Click Save
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Once you’ve reviewed all your entries, click “Save” to complete your dive
                        record.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FhrpEvYhTuRPByENJzMkVCg_doc.png?alt=media&token=4b876b9b-b616-4233-93e4-617ed57adec3"
                    alt="Click Save"
                />{" "}
                <h3 style={{ width: "100%", wordBreak: "break-word", maxWidth: "100%" }}>
                    35. See created dive record
                </h3>
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Now your dive record is clearly visible in the list of your dive records.
                        Next to it, you will see the status "Completed" indicating that this dive
                        record is fully done, but not yet verified. An icon next to it is the
                        button that you can click and get the verification link. This is the
                        process of verification of Level 1 and you can get more details by viewing
                        this section in our app. The last button that you can use is the copy
                        button. If you have a couple of similar dive records you are able to copy
                        one record and all of its values. Creating the next one will only include
                        changing a couple of items.
                    </p>
                </div>
                <img
                    width="100%"
                    src="https://static.guidde.com/v0/qg%2FFq3npFjfyvQxkIwy0x8IvVwy30W2%2Fv2GGphqvEwvd5qb7LfJBdg%2FjQykp5DDZyQLEo1wj3w3d4_doc.png?alt=media&token=0b92e176-3912-46f1-ada6-27334c7ac7ed"
                    alt="See created dive record"
                />
                <div style={{ marginTop: 16, marginBottom: 16 }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: "rgba(9, 12, 16, 0.6)",
                            width: "100%",
                            wordBreak: "break-word",
                            maxWidth: "100%"
                        }}
                    >
                        Congratulations! You've successfully created a historical dive record.
                        This record is now part of your official log and can be verified or edited
                        as needed. Happy diving, and keep those records accurate and up-to-date!
                    </p>
                </div>
            </div>

        </div>
    )
}

export default CreateHistoricalDiveRecord
